<template>
  <baidu-map   :center="mapKeyword" :zoom="15" :scroll-wheel-zoom="true">
    <bm-view class="map"></bm-view>
    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-map-type>
    <bm-marker :position="ag"> </bm-marker>
    <bm-control>
      <b-input v-model="mapKeyword" placeholder="请输入地名关键字"  style="position: absolute;top:10px;left:10px;width:240px"></b-input> <!-- 这里指代一个自定义搜索框组件 -->
    </bm-control>
  </baidu-map>
</template>

<script>
export default {
name: "map",
  data(){
  return {
    mapKeyword: '',
    ag: {
      lat: 0,
      lng: 0
    },
  }
  }
}

</script>

<style scoped>
.map {
  margin: 15px 116px;
  width: 1700px;
  height: 800px;
}
</style>